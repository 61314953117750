import React from "react"
import { graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"

const ContactPage = props => {
  const { data, location } = props
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle + ": contact"}>
      <SEO
        title="Contact"
        keywords={[`sharnik`, `wojciech ogrodowczyk`, `contact`, `newsletter`]}
      />
      <Bio />
      <h3>Thanks for contacting me!</h3>
      <p>
        I'll get back to you as soon as I can. Have a wonderful day
        <span role="img" aria-label="Hand wave">
          👋
        </span>
      </p>
    </Layout>
  )
}

export default ContactPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
